.summary {
    display: flex;
    height: 80vh;
    align-items: center;
}
.summary_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;
    width: 100%;
    height: 250px;
    background-color: rgb(28 48 92 / 0.8);
}
.summary__text {
    display: flex;
    flex-direction: column;
    animation: showText 1s forwards;
}
.summary__header {
    display: flex;
    flex-direction: column;
}

.summary__item {
    text-decoration: none;
    color: white;
}

.summary__title {
    font-size: 80px;
    font-weight: bold;
    font-weight: 500;
    color: white;
}

.summary__sub {
    font-size: 20px;
    font-weight: 200;
    color: white;
}

.summary__pic {
    height: 450px;
    border-radius: 50% 5% 50% 5%;
    object-fit: contain;
    border: 1px solid #47567b;
}

.summary__social {
    display: flex;
    margin-top: 20px;
    width: 80px;
    justify-content: space-between;
}

.summary__aboutme {
    border: none;
    background-color: rgb(28 48 92);
    box-shadow: 0px 2px 5px 0px black;
    color: white;
    padding: 10px 0px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 20px;
    width: 150px;
}

.summary__aboutme:hover {
    background-color: rgb(28 48 92 / 0.5);
}

.summary__buttons {
    display: flex;
    align-items: center;
}

.summary__cv {
    margin-left: 10px;
    background-color: white;
    color: rgb(28 48 92);
}

.summary__cv:hover {
    background-color: rgb(28 48 92 / 0.5);
    color: white;
}
@media (max-width: 768px) {
    .summary {
        height: 90vh;
    }
    .summary_container {
        flex-direction: column-reverse;
        height: 90vh;
        padding-top: 0px;
        padding-bottom: 0px;
        justify-content: center;
    }
    .summary__pic {
        margin-right: 0px;
        height: 300px;
        padding: 40px;
    }
    .summary__text {
        z-index: 9;
        justify-content: center;
    }
    .summary__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 1130px) {
    .summary__title {
        font-size: 50px;
    }
    .summary__pic {
        height: 325px;
    }
    .summary_container {
        height: 200px;
    }
    .summary__social {
        margin-top: 10px;
    }
    .summary__aboutme {
        margin-top: 10px;
    }
}

@keyframes showText {
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
    }
}