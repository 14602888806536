.header {
    height: 20px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    color: rgb(28 48 92);
    padding: 40px;
}

.header__left {
    display: flex;
    align-items: center;
}
.header__contents {
    display: flex;
    justify-content: space-between;
}

.header__item {
    margin-left: 20px;
    text-decoration: none;
    color: rgb(28 48 92);
}

@media (max-width: 768px) {
    .header {
      font-size: small;
    }
    .header {
        padding: 20px;
    }
}