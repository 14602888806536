.projects {
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
}

.projects__header {
    display: flex;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
}
.projects__container{
    display: flex;
    flex-direction: column;
}

.projects__project{
    display: flex;
    padding: 40px;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 20px;
    position: relative;
    align-items: center;
    box-shadow: 1px 1px 15px -10px black;
    animation: showDiv 1s forwards;
}

.projects__project__dark {
    background-color: rgb(28 48 92 / 0.7);
    color: white;
}

.project__details {
    padding: 30px;
    width: 100%;
}

.project__desc {
    padding-bottom: 30px;
    padding-right: 30px;
}

.project__name {
    font-size: xx-large;
    padding-bottom: 20px;
}

.project__link {
    font-style: italic;
}

.project__link :hover {
    visibility: visible;
}

.project__img__container {
    position: relative;
}

.project__img {
    width: 100%;
}

.project__img__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(28 48 92 / 0.5);
    display: none;
}

.project__img__container:hover .project__img__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
}

.project__gitbub {
    color: rgb(28 48 92);
}

.project__gitbub__icon {
    color: black;
    position: absolute;
    bottom: 50px;
}

@media (max-width: 768px) {
    .projects__project{
        flex-direction: column;
    }
    .project__img {
        width: 100%;
        height: 100%;
    }
}

@keyframes showPic {
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
    }
}