.work__container {
    display: flex;
    flex-direction: column;
}

.work__header {
    display: flex;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 3%;
}

.work__experience {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: rgb(28 48 92);
    padding: 30px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 15px -10px black;
    animation: showDivFromLeft 1s forwards;
}

.work__experience__dark {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 15px -10px black;
    animation: showDivFromRight 1s forwards;
    background-color: rgb(28 48 92 / 0.7);
    color: white;
}

.work__li {
    padding: 10px;
}
.work__exp__heading {
    padding-bottom: 10px;
    font-size: larger;
    font-weight: bold;
    border-bottom: 1px solid rgb(28 48 92);
}

.work__exp__content {
    padding-top: 20px;
}

.work__exp__features__heading{
    font-size: larger;
    font-weight: bold;
    margin: 20px;
    padding-bottom: 10px;
    justify-content: center;
    display: flex;
}

.work__features {
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 20px;
}

.work__list {
    display: flex;
    flex-direction: column;
    width: 25%;
    background-color: white;
    border-radius: 20px;
    height: 100%;
    position: sticky;
    top: 80px;
    box-shadow: 1px 1px 15px -10px black;
    transform: translateY(50%);
    transition: transform 1s ease;
}

.work__list__animate {
    transform: translateY(0);
}
.work__list > span {
    padding: 20px;
}

.work__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 20px;
    width: 65%;
    padding: 30px;
    box-shadow: 1px 1px 15px -10px black;
}

.work__demo__image, .work__demo__video {
    width: 90%;
    padding: 30px;
}

.work__feature {
    cursor: pointer;
}

.work__feature__name {
    margin-bottom: 30px;
}

.work__feature__active {
    background-color: rgb(28 48 92);
    color: white;
}

.work__feature__desc {
    text-align: justify;
    width: 90%;
}

.work__experience__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.work__demo__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work__timeline {
    padding-top: 40px;
    justify-content: center;
    display: flex;
    padding-bottom: 40px;
}

@media (max-width: 768px) {
    .work__demo__container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .work__demo__video, .work__demo__image {
        width: 70%;
    }
    .work__list {
        width: 100%;
        padding: 20px;
        position: static;
    }
    .work__right {
        margin-top: 20px;
        width: 100%;
        padding: 20px;
        height: 100%;
    }
    .work__list > span {
        padding: 5px;
    }
    .work__features {
        padding: 20px;
        align-items: flex-start;
    }
    .work__feature__desc {
        width: 90%;
    }
}

@keyframes showDivFromLeft {
    0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
    }
}

@keyframes showDivFromRight {
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
    }
}