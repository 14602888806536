.timeline {
    width: 80%;
}
.timeline__posts, .timeline__years {
    width: 100%;
    display: flex;
}

.timeline__stats {
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: bold;
}

.timeline__stats_1 {
    background-color: rgb(28 48 92 / 0.3);
    width: 15%;
    padding: 5px;
    color: white;
}
.timeline__stats_2 {
    background-color: rgb(28 48 92 / 0.6);
    width: 20%;
    padding: 5px;
    color: white;
}
.timeline__stats_3 {
    background-color: rgb(28 48 92);
    width: 100%;
    padding: 5px;
    color: white;
}

.timeline__years{
    display: flex;
    font-size: xx-small;
}

.timeline__year {
    display: flex;
    align-items: center;
}

.timeline__year1 {
    width: 15%;
    padding-left: 5px;
    padding-right: 5px;
}
.timeline__year2 {
    width: 20%;
    padding-left: 5px;
    padding-right: 5px;
}
.timeline__year3 {
    width: 98%;
    padding-left: 5px;
    padding-right: 5px;
}