.footer {
    color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__greetings {
    color: rgb(28 48 92);
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-bottom: 10px;
    font-size: xx-large;
    text-align: center;
}

.footer__container {
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
    background-color: rgb(28 48 92 / 0.8);
    border-radius: 20px;
}

.footer__left, .footer__right {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
    height: 100%;
}

.footer__heading {
    font-weight: bold;
    padding-bottom: 5px;
}

.footer__sub {
    padding-bottom: 15px;
}

.footer_qr {
    width: 100px
}

.footer__owner {
    color: rgb(28 48 92);
    justify-content: center;
    display: flex;
    margin: 10px;
}

.footer__bottom {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

}
.footer__flag {
    width: 20px;
    height: 20px;
}
@media (max-width: 768px) {
    .footer__container {
      flex-direction: column;
      align-items: flex-start;
      width: 70%;
    }
  }